import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { graphql, navigate, useStaticQuery } from "gatsby"
import styled from "styled-components"
import ResponsiveSpacing from "../components/responsive-spacing"
import Layout from "../components/layout"
import Inner from "../components/inner"
import SEO from "../components/seo"
import { Row, Col } from "../components/rows"
import Shopify from "../utils/shopify"

const OrdersStyled = styled.div`
  .orderLine {
    margin: 16px 0;
    padding: 16px 16px 0px 16px;
    font-size: 1.6em;
    border: 2px solid ${(p) => p.theme.Blue};
    border-radius: 16px;
    line-height: 30px;
    overflow: hidden;
    position: relative;
    min-width: 890px;

    @media (max-width: 890px) {
      min-width: 0px;
      padding: 6px 16px 0 16px;
    }

    @media (max-width: 500px) {
      width: 94vw;
    }
  }
  .topInfo {
    color: ${(p) => p.theme.DarkBlue};
    display: grid;
    grid-template-columns: 180px 130px 130px 130px 130px;
    grid-gap: 8px;
    align-items: flex-end;
    font-size: 1.6em;
    font-family: "interstate-compressed";
    height: 60px;

    @media (max-width: 890px) {
      height: 140px;
      grid-template-columns: 180px 180px;
    }
  }
  .customerURL {
  }
  .orderNumber {
    font-weight: bold;
  }
  .processedAt {
  }
  .orderLine {
  }
  .processedAt {
    @media (max-width: 890px) {
      text-align: right;
    }
  }
`
// <a href={o.customerUrl} className="customerURL">
//   Change Order
// </a>

const OrderItemsStyled = styled.div`
  position: relative;
  .viewButton {
    user-select: none;
    display: inline-block;
    background: ${(p) => p.theme.Blue};
    color: ${(p) => p.theme.White};
    font-size: 1.6em;
    letter-spacing: 1px;
    font-family: "interstate-compressed";
    padding: 5px 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: -40px;
    &:hover {
      background: ${(p) => p.theme.Blue};
    }
  }
  .orderItems {
    height: auto;
    max-height: ${(p) => (p.open ? "10000px" : 0)};
    transition: max-height 300ms;
    overflow: hidden;
    margin: 0 -15px;
    padding: 10px;
    font-size: 0.78em;
    display: block;
    background: ${(p) => p.theme.LightestBlue};
    position: relative;
  }
  .orderItemsInside {
    margin: 0 -15px;
    padding: 10px 10px 20px 10px;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    position: relative;
  }
  .orderItem {
    position: relative;
    padding: 16px;
    margin: 10px;
    background: ${(p) => p.theme.White};
    border-radius: 10px;
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 12px;

    .image img {
      width: 80px;
    }

    .price {
      font-size: 1.2em;
      font-weight: bold;
      color: ${(p) => p.theme.DarkBlue};
    }

    .info {
      & > h5 {
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
        padding: 0 0 5px 0;
        line-height: 1.6em;
        line-height: 1em;
        color: ${(p) => p.theme.DarkBlue};
      }
    }

    @media (max-width: 768px) {
      margin: 2px 10px;
      padding: 6px;
    }
  }
  .lineItems {
    color: ${(p) => p.theme.Blue};
    font-weight: bold;
    font-size: 0.8em;
    line-height: 1.7em;
    margin: 0;
    padding: 0 0 0 12px;
  }
  .lineItem {
  }
`

const OrderItems = ({ items }) => {
  const [open, setOpen] = useState(false)
  return (
    <OrderItemsStyled open={open}>
      <span
        tabIndex="0"
        role="button"
        className={`viewButton`}
        onClick={(e) => setOpen(!open)}
      >
        ORDER DETAILS
      </span>
      <div className="orderItems">
        <ul className="orderItemsInside">
          {items.map((item, i2) => {
            if (item.variant && parseInt(item.variant.priceV2.amount) === 0)
              return ""
            // if (item.variant) console.log(item.variant.priceV2.amount)
            return (
              <li key={i2} className="orderItem">
                <div className="image">
                  {item.variant ? (
                    <img src={item.variant.image.transformedSrc} />
                  ) : (
                    ""
                  )}
                  <div className="price">
                    ${item.variant ? item.variant.priceV2.amount : ""}
                  </div>
                </div>
                <div className="info">
                  <h5>{item.title}</h5>
                  <ol className="lineItems">
                    {item.customAttributes.map((li, i3) => {
                      return (
                        <li className="lineItem" key={i3}>
                          {li.value}
                        </li>
                      )
                    })}
                  </ol>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </OrderItemsStyled>
  )
}

const Orders = ({ orders }) => {
  if (typeof orders === "undefined" || !orders) return ""
  const ord = orders.edges
    .map((e) => e.node)
    .sort((a, b) =>
      parseInt(a.orderNumber) < parseInt(b.orderNumber) ? 1 : -1
    )
  return (
    <OrdersStyled>
      {ord.map((o, i1) => {
        const items = o.lineItems.edges.map((e) => e.node)
        return (
          <div className="orderLine" key={i1}>
            <div className="topInfo">
              <div>
                <span className="orderNumber">Order #{o.orderNumber}</span>
                <br />
                <span className="processedAt">
                  {new Date(o.processedAt).toLocaleString()}
                </span>
              </div>
              <span className="subtotal">
                Subtotal: ${parseFloat(o.subtotalPriceV2.amount).toFixed(2)}
              </span>
              <span className="shipping">
                Shipping: $
                {parseFloat(o.totalShippingPriceV2.amount).toFixed(2)}
              </span>
              <span className="tax">
                Tax: ${parseFloat(o.totalTaxV2.amount).toFixed(2)}
              </span>
              <span className="price">
                Total: ${parseFloat(o.totalPriceV2.amount).toFixed(2)}
              </span>
            </div>
            <OrderItems items={items} />
          </div>
        )
      })}
    </OrdersStyled>
  )
}

const UserInfoStyled = styled.div`
  background: ${(p) => p.theme.LightestBlue};

  padding: 20px;

  @media (max-width: 768px) {
    margin: 0;
  }

  h3 {
    font-size: 2.4em;
    text-decoration: none;
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};
    margin: 0;
  }

  p {
    line-height: 1.4em;
    font-size: 1.4em;
  }

  span {
    display: block;
  }
`

const UserInfo = ({ customer }) => {
  if (!customer) return ""
  const address = customer.defaultAddress
  return (
    <UserInfoStyled>
      <h3>MY INFO</h3>
      <h4>
        {customer.firstName}
        <br />
        {customer.lastName}
      </h4>
      <p>
        <span>{customer.email}</span>
        <span>{address && address.formatted[0]}</span>
        <span>{address && address.formatted[1]}</span>
        <span>{address && address.formatted[2]}</span>
        <span>{customer.phone}</span>
      </p>
    </UserInfoStyled>
  )
}

const AccountInfoStyled = styled.div`
  padding: 50px 0 150px 0;
  min-height: 600px;
  margin: 93px auto 0 auto;

  .heading {
    display: grid;
    grid-template-columns: 200px 1fr;
  }

  .logout {
    font-size: 2.4em;
    text-decoration: none;
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};
    font-size: 3.2em;
    margin: 0 0 28px 0;
    letter-spacing: 2px;
    width: 200px;
  }

  h2 {
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.Blue};
    font-size: 3.2em;
    margin: 0 0 28px 0;
    letter-spacing: 2px;
  }

  h4 {
    font-family: "Montserrat";
    color: ${(p) => p.theme.Blue};
    text-transform: uppercase;
    font-size: 1.4em;
    margin: 5px 0 5px 0;
    letter-spacing: 2px;
  }

  p {
    color: ${(p) => p.theme.DarkBlue};
    font-size: 1.4em;
    letter-spacing: 1.2px;
  }
`

const AccountInfo = ({ user, dispatch, fields }) => {
  const {
    account_headline,
    orders_heading,
    logout_label,
    instructions,
    empty_wording,
  } = fields

  useEffect(() => {
    if (!user.accessToken) navigate("/login")
    Shopify.get("/get_customer")
      .then((raw) => raw.json())
      .then((json) => {
        if (json.data.customer)
          dispatch({ type: "CUSTOMER_RECEIVED", customer: json.data.customer })
      })
      .catch((err) => console.log(err))
  }, [])

  const orders = !!user.customer && user.customer.orders
  return (
    <AccountInfoStyled>
      <ResponsiveSpacing>
        <Inner maxWidth="1400px">
          <div className="heading">
            <h1 tabIndex="0">{account_headline}</h1>
            <div className="">
              <a
                href="#"
                className="logout"
                onClick={(e) => {
                  dispatch({ type: "LOGOUT" })
                  navigate("/")
                }}
              >
                {logout_label}
              </a>
            </div>
          </div>
          <Row columns="1fr 300px" gap="60px" width="1400px">
            <Col>
              <h2>{orders_heading}</h2>
              {orders && orders.edges.length > 0 ? (
                <p>{instructions}</p>
              ) : (
                <p>{empty_wording}</p>
              )}
              <Orders orders={orders} />
            </Col>
            <Col>
              <UserInfo customer={user.customer} />
            </Col>
          </Row>
        </Inner>
      </ResponsiveSpacing>
    </AccountInfoStyled>
  )
}

const ConnectedAccountInfo = connect((state) => state)(AccountInfo)

const AccountPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "account-page" } }) {
        edges {
          node {
            acf {
              account_headline
              orders_heading
              logout_label
              instructions
              empty_wording
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  return (
    <Layout>
      <SEO title="Account" />
      <ConnectedAccountInfo fields={fields} />
    </Layout>
  )
}

export default AccountPage
